import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import calculateCompoundInterest from '../utils/compoundCalculator';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Compounder() {
  // Set default values to match the example
  const [initialAmount, setInitialAmount] = useState(15000);
  const [amount, setAmount] = useState(100);
  const [contributionFrequency, setContributionFrequency] = useState('daglig');
  const [interestFrequency, setInterestFrequency] = useState('månatlig');
  const [rate, setRate] = useState(5.25);
  const [years, setYears] = useState(10);
  const [result, setResult] = useState(null);
  const [chartData, setChartData] = useState(null);

  const handleCalculate = () => {
    const params = {
      initialAmount: parseFloat(initialAmount),
      recurringContribution: parseFloat(amount),
      annualInterestRate: parseFloat(rate),
      interestFrequency: interestFrequency,
      contributionFrequency: contributionFrequency,
      years: parseFloat(years)
    };

    if (isNaN(params.initialAmount) || params.initialAmount < 0 || isNaN(params.recurringContribution) || params.recurringContribution < 0) {
      alert('Ange giltiga belopp.');
      return;
    }

    const finalAmount = calculateCompoundInterest(params);
    setResult(finalAmount);

    const data = [];
    for (let year = 1; year <= years; year++) {
      const yearParams = { ...params, years: year };
      const yearAmount = calculateCompoundInterest(yearParams);
      data.push(yearAmount);
    }

    setChartData({
      labels: Array.from({ length: years }, (_, i) => i + 1),
      datasets: [
        {
          label: 'Utveckling över tid',
          data: data,
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
          tension: 0.1
        }
      ]
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">
        Har du koll på <strong>"ränta-på-ränta effekten"</strong> och hur den påverkar din privatekonomi?
      </h2>

      {/* Initial Amount Input */}
      <div className="mb-4">
        <label htmlFor="initialAmount" className="block text-gray-700 text-sm font-bold mb-2">Startbelopp</label>
        <input type="number" id="initialAmount" value={initialAmount} onChange={(e) => setInitialAmount(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
      </div>

      {/* Regular Contribution Input */}
      <div className="mb-4">
        <label htmlFor="amount" className="block text-gray-700 text-sm font-bold mb-2">Regelbundet sparande</label>
        <input type="number" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
      </div>

      {/* Contribution Frequency */}
      <div className="mb-4">
        <label htmlFor="contributionFrequency" className="block text-gray-700 text-sm font-bold mb-2">Insättningsfrekvens</label>
        <select id="contributionFrequency" value={contributionFrequency} onChange={(e) => setContributionFrequency(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <option value="daglig">daglig</option>
          <option value="veckovis">veckovis</option>
          <option value="månatlig">månatlig</option>
        </select>
      </div>

      {/* Interest Frequency */}
      <div className="mb-4">
        <label htmlFor="interestFrequency" className="block text-gray-700 text-sm font-bold mb-2">Ränteutbetalningsfrekvens</label>
        <select id="interestFrequency" value={interestFrequency} onChange={(e) => setInterestFrequency(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <option value="daglig">daglig</option>
          <option value="månatlig">månatlig</option>
          <option value="kvartalsvis">kvartalsvis</option>
          <option value="årlig">årlig</option>
        </select>
      </div>

      {/* Rate Input */}
      <div className="mb-4">
        <label htmlFor="rate" className="block text-gray-700 text-sm font-bold mb-2">Ränta (nominell)</label>
        <input type="number" id="rate" value={rate} onChange={(e) => setRate(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
      </div>

      {/* Years Input */}
      <div className="mb-4">
        <label htmlFor="years" className="block text-gray-700 text-sm font-bold mb-2">Tidsperiod (år)</label>
        <input type="number" id="years" value={years} onChange={(e) => setYears(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
      </div>

      {/* Calculate Button */}
      <button onClick={handleCalculate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Beräkna!</button>

      {/* Result Display */}
      {result && <div className="mt-4">Med en årlig avkastning på <strong>{rate} %</strong> skulle din besparing vara värd <strong>{result}</strong> SEK.</div>}

      {/* Chart Display */}
      {chartData && (
        <div className="mt-4">
          <Line data={chartData} />
        </div>
      )}
    </div>
  );
}

export default Compounder;
