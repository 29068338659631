export function calculateCompoundInterest(params) {
  const {
    initialAmount, // Startkapital (PV)
    recurringContribution, // Återkommande insättning (PMT)
    annualInterestRate, // Nominell årlig ränta (R)
    interestFrequency, // Ränta betalas ut (årlig, kvartalsvis, månatlig, daglig)
    contributionFrequency, // Insättning sker (daglig, veckovis, månatlig)
    years, // Antal år för sparande (t)
  } = params;

  // Convert frequencies to number of times per year
  const interestFrequencies = {
    'årlig': 1,
    'kvartalsvis': 4,
    'månatlig': 12,
    'daglig': 365,
  };

  const contributionFrequencies = {
    'daglig': 365,
    'veckovis': 52,
    'månatlig': 12,
  };

  // Determine compounding and contribution periods
  const compoundingPeriodsPerYear = interestFrequencies[interestFrequency];
  const contributionsPerYear = contributionFrequencies[contributionFrequency];

  // Interest rate per compounding period
  const ratePerCompoundingPeriod = annualInterestRate / 100 / compoundingPeriodsPerYear;

  // Total number of compounding periods
  const totalCompoundingPeriods = years * compoundingPeriodsPerYear;

  // Step 1: Calculate Future Value of the Lump Sum (Initial Amount)
  const FV_lumpSum = initialAmount * Math.pow((1 + ratePerCompoundingPeriod), totalCompoundingPeriods);

  // Step 2: Calculate Future Value of Recurring Contributions (Annuity)
  const ratePerContributionPeriod = annualInterestRate / 100 / contributionsPerYear;
  const totalContributionPeriods = years * contributionsPerYear;

  let FV_contributions = 0;
  if (ratePerContributionPeriod > 0) {
    FV_contributions = recurringContribution *
      ((Math.pow(1 + ratePerContributionPeriod, totalContributionPeriods) - 1) / ratePerContributionPeriod) *
      (1 + ratePerContributionPeriod);
  } else {
    // If interest rate is 0, contributions do not grow with interest
    FV_contributions = recurringContribution * totalContributionPeriods;
  }

  // Step 3: Combine Future Values
  const futureValue = FV_lumpSum + FV_contributions;

  return futureValue.toFixed(2);
}

export default calculateCompoundInterest;
